.grid line {
  stroke: #7a7373;
  stroke-width: 0.5px;
}
.axis line {
  stroke: lightgray;
  stroke-width: 1px;
}
/* .axis text {
  fill: lightgray;
  stroke-width: 1px;
} */
.axis text.label {
  font-size: 12px;
}
.axis path.arrow {
    fill: lightgray;
}
