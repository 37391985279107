.leaflet-container {
    height: 600px;
}

.leaflet-marker-icon {
    margin-top: -25px!important;
}
.leaflet-container {
    background: transparent!important;
    outline: 0;
}
.leaflet-popup {
    bottom: 20px!important;
}
.leaflet-bar a, .leaflet-bar a:hover {
    background-color: #808080!important;
    border-color: #272727!important;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #292929!important;
}
