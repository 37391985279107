.presentSlide {
    max-height: fit-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    display: none;
    position: absolute;
    width: 100%;
    /* height: 100%; */
    padding: 20px 0;
    pointer-events: auto;
    z-index: 10;
    /* transform-style: flat; */

    text-align: center;
    /* position: relative; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    overflow-y: hidden;
}

.presentSlide-active {
    display: block;
}

.scaleable-wrapper {
    padding: 20px;
    position: relative;
    background: rgb(26, 26, 26);
    height: 100%;
}

.presBtnLeft {
    position: fixed;
    top: 30px;
    right: 60px;
    z-index: 2000;
    border: none;
    padding: 0px;
    width: 30px;
}

.presBtnRight {
    position: fixed;
    top: 30px;
    right: 10px;
    z-index: 2000;
    border: none;
    padding: 0px;
    width: 30px;
}

.slides {
    font-size: 20px;
    overflow: hidden;
}

.slides ol, .slides dl, .slides ul {
    display: inline-block;
    text-align: left;
    margin: 0 0 0 1em;
}

.slides h1, .slides h2, .slides h3 {
    text-transform: uppercase;
    word-wrap: break-word;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: normal;
}

.slides h1 {
    font-size: 2.5em;
}

.slides h2 {
    font-size: 1.6em;
}

.slides h2 {
    font-size: 1.3em;
}

