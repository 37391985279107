body {
  margin: 0;
}

html, body, div, input, button, p, textarea, select {
  font-family: 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(226, 226, 226);
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), url(./assets/fonts/RobotoCondensed-Regular.ttf) format('truetype');
}

.ant-row {
  width: 100%;
}

.ant-input-affix-wrapper {
  padding: 0!important;
}

.ant-input-suffix {
  margin-left: 0!important;
}

.ant-input-number-affix-wrapper {
  padding: 0!important;
}
