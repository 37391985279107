.ant-form-item {
    display: flex;
}

.ant-form-item-label {
    max-width: 20%;
    text-align: end!important;
    padding-right: 10px!important;
    overflow: visible!important;
}

.ant-form-item-label > label {
    overflow: visible!important;
}

.ant-form-item {
    margin-bottom: 10px;
}

#AbiInputForm {
    position: absolute;
    bottom: 0px;
    max-height: 80%;
    overflow-y: auto;
    width: 100%;
    padding-top: 20px;
    background: #212121;
}

#AbiInputForm .form-group {
    width: calc(100% - 40px);
}

.AbiInputFormSubmit {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
}

.ant-btn-primary, .ant-btn-dangerous.ant-btn-primary {
    background: rgb(26, 26, 26)!important;
    border-width: 2px;
    border-style: double;
    border-color: rgb(80,80,80)!important;
}

.ant-btn-primary:hover, .ant-btn-dangerous.ant-btn-primary:hover {
    background: rgb(56, 56, 56)!important;
    border-width: 2px;
    border-style: double;
    border-color: rgb(80,80,80)!important;
}
