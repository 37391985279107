@import '~antd/dist/antd.dark.css';
@import './components/schema/form.css';

html, body, #root, .App {
  width: 100%;
  height: 100%;
  background: #212121;
  overflow-x: hidden;
  overflow-y: hidden;
}

.App {
  overflow-wrap: break-word;
}

.App-link {
  color: #61dafb;
}

.MarkdownPreview {
  overflow-x: hidden;
}

.cm-editor, .cm-gutter, .cm-gutters {
  background-color: #212121!important;
}

.cm-activeLine, .cm-activeLineGutter {
  background-color: #151617!important;
}

.MarkdownPreview img {
  max-width: 35%;
}

.MarkdownEditor {
  overflow-y: auto;
}

.ͼf {
  color: #6f6fbb!important;
}

.ͼa {
  color: #873693!important;
}

.ͼd {
  color: #972828!important;
}

button {
  border-radius: 5px;
  background: rgb(26, 26, 26);
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  border-width: 2px;
  border-style: double;
  border-color: rgb(80,80,80);
}

a {
  cursor: pointer;
}

button:hover {
  background: rgb(80 80 80 / 50%);
}

input, textarea, select, .ant-input, [class^='ant-input'] {
  border-radius: 5px;
  background: rgb(80 80 80 / 50%)!important;
  outline: none;
  border-color: rgb(80 80 80 / 50%);
  border-width: thin;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px rgb(42, 42, 42) inset!important; /* Change the color to your own background color */
  -webkit-text-fill-color: #eee;
  background-color: transparent !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(42, 42, 42) inset!important;/*your box-shadow*/
  -webkit-text-fill-color: #eee;
  background-color: transparent !important;
}

input#root:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(42, 42, 42) inset!important;/*your box-shadow*/
  -webkit-text-fill-color: #eee;
  background-color: transparent !important;
}

hr {
  opacity: 0.2;
}

input[type=file]::-webkit-file-upload-button {
  background: rgb(26, 26, 26);
  color: rgb(207, 207, 207);
  opacity: 0.3;
  border: none;
  outline: none;
  border-color: rgb(80 80 80 / 50%);
  border-width: thin;
}

input[type=file]::-webkit-file-upload-button:hover {
  background: rgb(80 80 80 / 50%);
}

input, select {
  min-height: 35px;
}

.btn-icon:hover {
  background: rgb(80 80 80 / 50%);
}

.CodeMirror-linenumber {
  color: rgb(83, 83, 83)!important;
}
.ant-menu {
  z-index: 10000!important;
}

.editorForView {
  border-left: 1px solid gray;
  overflow-y: auto;
}

.ant-table-cell {
  padding: 1px 2px!important;
}

.ant-tabs-nav {
  margin: 0;
  line-height: 10px;
}

.markdown-toc ul {
  padding-left: 15px;
}

.markdown-toc li {
  list-style-type: none;
}

.ManualPreview {
  height: 300px;
}

.react-json-view {
  overflow-wrap: anywhere;
}

.CodeMirror-selectedLine {
  background: rgba(0,0,0,0.3);
}

.btn-transparent {
  border-width: 0px;
  padding-right: 5px;
  background: transparent;
}

/* to counteract the index.css 16px general font-size */
.ant-menu-title-content span {
  font-size: 14px!important;
}

.ant-menu, .ant-menu-submenu {
  background: rgb(26, 26, 26);
}

.info-text {
  color: #a59c5b;
}

.cm-ySelectionInfo {
  width: 280px;
}
